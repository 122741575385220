import * as React from 'react';
import {PropsWithChildren} from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import Grid from '../soul/grid/Grid';
import {GridDirection} from '../soul/grid/GridDirection';
import {GridDensity} from '../soul/grid/GridDensity';
import GridItem from '../soul/grid/GridItem';
import Logo from '../../assets/img/ESTECO.inline.svg';
import Svg from '../Svg';
import FooterMenu from '../utils/footerMenu';
import EstecoColumn from '../estecoColumn/EstecoColumn';
import {GridBreakpoint} from '../soul/grid/GridBreakpoint';

const GATSBY_EXCLUDED = process.env.GATSBY_EXCLUDED;

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
const subsitesPath = {
    all: [/\/india\/.*/, /\/north-america\/.*/],
    india: [/\/india\/agenda.*/],
    na: [/\/north-america\/.+/],
}
interface FooterProps {
    menu: {
        estecoMenu: {
            url: string,
            label: string,
            enabled: boolean,
        }[],
        socialMenu: {
            url: string,
            label: string,
            icon: string,
            enabled: boolean
        }[],
        copyright: {
            text: string,
            menu: {
                url: string,
                label: string,
                enabled: boolean,
            }[]
        }
    }
}

const Footer: React.FC<PropsWithChildren> = ({children}) => {
    const menu: FooterProps= useStaticQuery(componentQuery);
    let {estecoMenu, socialMenu, copyright} = menu.menu;
    estecoMenu = Object.values(estecoMenu).filter(link => link.enabled);
    socialMenu = Object.values(socialMenu).filter(link => link.enabled);

    return (
        <div className="footer">
            <EstecoColumn fullWidthBG={true} className="soul-background-neutral-50 soul-space-stack-both-2-xl">
                <Grid direction={GridDirection.HORIZONTAL} density={GridDensity.COMFORTABLE} wrap={true}>
                    {children
                    ? <GridItem colSpan={3} responsive={[
                            {
                                size: 6,
                                breakpoint: GridBreakpoint.S
                            },
                            {
                                size: 12,
                                breakpoint: GridBreakpoint.XS
                            },
                        ]}>
                        {children}
                    </GridItem>
                    : '' }
                    <GridItem colSpan={children ? 3 : 4} responsive={[
                        {
                            size: 6,
                            breakpoint: GridBreakpoint.S
                        },
                        {
                            size: 12,
                            breakpoint: GridBreakpoint.XS
                        },
                    ]}>
                        <div className="soul-label soul-label--reduced soul-heading--h6 h-text-bold">ESTECO</div>
                        <nav className="soul-space-stack-top-m">
                            <FooterMenu menu={estecoMenu} />
                        </nav>
                    </GridItem>
                    <GridItem colSpan={children ? 3 : 4} responsive={[
                        {
                            size: 6,
                            breakpoint: GridBreakpoint.S
                        },
                        {
                            size: 12,
                            breakpoint: GridBreakpoint.XS
                        },
                    ]}>
                        <div className="soul-label soul-label--reduced soul-heading--h6 h-text-bold">SOCIAL</div>
                        <nav className="soul-space-stack-top-m">
                            <FooterMenu menu={socialMenu} />
                        </nav>
                    </GridItem>
                    <GridItem colSpan={children ? 3 : 4} responsive={[
                        {
                            size: 6,
                            breakpoint: GridBreakpoint.S
                        },
                        {
                            size: 12,
                            breakpoint: GridBreakpoint.XS
                        },
                    ]}>
                        <Svg color="#59585B" className="esteco-logo--thin"><Logo /></Svg>
                        <p className="soul-space-stack-top-m soul-font-scale-s" dangerouslySetInnerHTML={{__html: copyright.text}} />
                        <nav className="soul-space-stack-top-m">
                            <FooterMenu menu={copyright.menu} />
                        </nav>
                    </GridItem>
                </Grid>
            </EstecoColumn>
        </div>
    );
}

const componentQuery = graphql`
    query footerContent {
      menu: indexJson(jsonData: {eq: "footer-menu"}) {
        estecoMenu {
          url
          label
          enabled
        }
        socialMenu {
          url
          label
          icon
          enabled
        }
        copyright {
          text
          menu {
            url
            label
            enabled
          }
        }
      }
    }
`;

export default Footer;
