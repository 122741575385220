/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
export enum Icons {
    ADD = 'add',
    ADD_TO_STORAGE = 'add-to-storage',
    ADVISOR = 'advisor',
    ALIGN_LEFT = 'align-left',
    ALIGN_RIGHT = 'align-right',
    APPS = 'apps',
    ASPECT_RATION = 'aspect-ration',
    ATTACHMENT = 'attachment',
    AVATAR = 'avatar',
    BACKWARD = 'backward',
    BOLD = 'bold',
    BOOKMARK_EMPTY = 'bookmark-empty',
    BOOKMARK_FULL = 'bookmark-full',
    BPMN = 'bpmn',
    BUBBLE = 'bubble',
    BUSINESS = 'business',
    BUSINESS_PROJECT = 'business-project',
    CALENDAR = 'calendar',
    CHAT_BUBBLE = 'chat-bubble',
    CHECKBOX_CHECKED = 'checkbox-checked',
    CHECKBOX_UNCHECKED = 'checkbox-unchecked',
    CHECK_ALL = 'check-all',
    CHECK_FULL = 'check-full',
    CLONE = 'clone',
    CLOSE = 'close',
    CLOUD_DONE = 'cloud-done',
    CODE = 'code',
    COLORS = 'colors',
    COLOR_RESET = 'color-reset',
    COPY = 'copy',
    COPY_FILE = 'copy-file',
    CUT = 'cut',
    CREDIT_CARD = 'credit-card',
    DASHBOARD = 'dashboard',
    DATASET = 'dataset',
    DELETE_ALL = 'delete-all',
    DELETE_FOREVER = 'delete-forever',
    DESCRIPTION = 'description',
    DMN = 'dmn',
    DONE = 'done',
    DONE_ALL = 'done-all',
    DOWN = 'down',
    DOWNLOAD = 'download',
    DOWNWARD = 'downward',
    DRAG = 'drag',
    EDIT = 'edit',
    ELEVATOR = 'elevator',
    EQUALIZER = 'equalizer',
    EQUALIZER_CHART = 'equalizer-chart',
    ERROR = 'error',
    EXPERIMENTAL = 'experimental',
    EXPLORE = 'explore',
    EXPORT_TABLE = 'export-table',
    FACEBOOK = 'facebook',
    SHOW = 'show',
    FEEDBACK = 'feedback',
    FILE = 'file',
    FILTER = 'filter',
    FIND_IN_PAGE = 'find-in-page',
    FIRST_PAGE = 'first-page',
    FITNESS = 'fitness',
    FLIP_HORIZONTAL = 'flip-horizontal',
    FLIP_VERTICAL = 'flip-vertical',
    FOLDER = 'folder',
    FOLDER_OPEN = 'folder-open',
    FORMAT_CLEAR = 'format-clear',
    FORMAT_SIZE = 'format-size',
    FORWARD = 'forward',
    FULL_SCREEN_ENTER = 'full-screen-enter',
    FULL_SCREEN_EXIT = 'full-screen-exit',
    FUNNEL = 'funnel',
    GOOGLE = 'google',
    GRID = 'grid',
    GRID_VIEW = 'grid-view',
    GROUP = 'group',
    HEART_EMPTY = 'heart-empty',
    HEART_FULL = 'heart-full',
    HELP = 'help',
    HIDE = 'hide',
    HIERARCHY = 'hierarchy',
    HIGHLIGHT = 'highlight',
    HIGHLIGHT_OFF = 'highlight-off',
    HISTORY = 'history',
    HOURGLASS = 'hourglass',
    IMAGE = 'image',
    INACTIVE = 'inactive',
    INBOX = 'inbox',
    INDENT_DECREASE = 'indent-decrease',
    INDENT_INCREASE = 'indent-increase',
    INFINITE = 'infinite',
    INFO = 'info',
    INFORMATION = 'information',
    INSTAGRAM = 'instagram',
    ITALIC = 'italic',
    KEYBOARD = 'keyboard',
    LAST_PAGE = 'last-page',
    LAUNCH = 'launch',
    LAYERS = 'layers',
    LEFT = 'left',
    LICENSE = 'license',
    LINE_HEIGHT = 'line-height',
    LINK = 'link',
    LINKEDIN = 'linkedin',
    LIST = 'list',
    LIST_VIEW = 'list-view',
    LINE_STYLE = 'line-style',
    LOCATE = 'locate',
    LOCK = 'lock',
    LOCK_FILLED = 'lock-filled',
    LOGOUT = 'logout',
    LOOP = 'loop',
    MAIL = 'mail',
    METADATA = 'metadata',
    MOBILE = 'mobile',
    MORE = 'more',
    MOVE = 'move',
    MOVE_TO_INBOX = 'move-to-inbox',
    NEW_FILE = 'new-file',
    NEW_FOLDER = 'new-folder',
    NEWS = 'news',
    NOTICE = 'notice',
    NOTIFICATION = 'notification',
    NO_ENTRY = 'no-entry',
    ORDERED_LIST = 'ordered-list',
    PASSWORD = 'password',
    PASTE = 'paste',
    PAUSE = 'pause',
    PEOPLE = 'people',
    PEOPLE_OUTLINE = 'people-outline',
    PERSON_OUTLINE = 'person-outline',
    PLAY = 'play',
    PLAY_ALL = 'play-all',
    PLAY_SQUARE = 'play-square',
    PRESENT_TO_ALL = 'present-to-all',
    PROCESS_DEFINITION = 'process-definition',
    PROCESS_INSTANCE = 'process-instance',
    PROJECT = 'project',
    PUZZLE = 'puzzle',
    QUEUE = 'queue',
    QUOTE = 'quote',
    RADAR = 'radar',
    RADIO_CHECKED = 'radio-checked',
    RADIO_UNCHECKED = 'radio-unchecked',
    READER_MODE = 'reader-mode',
    RECEIPT = 'receipt',
    REDO = 'redo',
    REFERENCE = 'reference',
    REFRESH = 'refresh',
    REMOVE = 'remove',
    REPEAT = 'repeat',
    RESTORE = 'restore',
    RIGHT = 'right',
    ROCKET = 'rocket',
    ROTATE_CLOCKWISE = 'rotate-clockwise',
    ROTATE_COUNTERCLOCKWISE = 'rotate-counterclockwise',
    RSM = 'rsm',
    SAMPLES = 'samples',
    SEARCH = 'search',
    SECURITY = 'security',
    SECURITY_VERIFIED = 'security-verified',
    SEND = 'send',
    SERVER = 'server',
    SESSION = 'session',
    SETTINGS = 'settings',
    SHARE = 'share',
    SHARED = 'shared',
    SKIP_NEXT = 'skip-next',
    SKIP_PREVIOUS = 'skip-previous',
    SLOW_MOTION = 'slow-motion',
    SORT = 'sort',
    SORT_ASCENDING = 'sort-ascending',
    SORT_DESCENDING = 'sort-descending',
    SORT_NONE = 'sort-none',
    SPACE_ADD = 'space-add',
    SPACE_REMOVE = 'space-remove',
    STARRED = 'starred',
    STAR_EMPTY = 'star-empty',
    STAR_FULL = 'star-full',
    STOP = 'stop',
    STORAGE = 'storage',
    STRIKETHROUGH = 'strikethrough',
    STUDY = 'study',
    SWAP = 'swap',
    TAG = 'tag',
    TASKS = 'tasks',
    TEXT_ALIGN_CENTER = 'text-align-center',
    TEXT_ALIGN_LEFT = 'text-align-left',
    TEXT_ALIGN_RIGHT = 'text-align-right',
    TEXT_JUSTIFY = 'text-justify',
    THUMB_DOWN = 'thumb-down',
    THUMB_UP = 'thumb-up',
    TIME = 'time',
    TIMELAPSE = 'timelapse',
    TITLE = 'title',
    TRASH = 'trash',
    TREE = 'tree',
    TRENDING = 'trending',
    TWITTER = 'twitter',
    UNDERLINED = 'underlined',
    UNDO = 'undo',
    UNLOCK = 'unlock',
    UNORDERED_LIST = 'unordered-list',
    UNSHARE = 'unshare',
    UP = 'up',
    UPLOAD = 'upload',
    UPLOAD_FILE = 'upload-file',
    UPLOAD_FOLDER = 'upload-folder',
    UPWARD = 'upward',
    USER_REPOSITORY = 'user-repository',
    VIEW_COMFY = 'view-comfy',
    VIMEO = 'vimeo',
    WAIT = 'wait',
    WALLPAPER = 'wallpaper',
    WARNING = 'warning',
    WEBAPP = 'webapp',
    WEBSITE = 'website',
    WIDGETS = 'widgets',
    WORKFLOW = 'workflow',
    YOUTUBE = 'youtube',
    ZOOM_IN = 'zoom-in',
    ZOOM_OUT = 'zoom-out',
    ZOOM_RESET = 'zoom-reset'
}
