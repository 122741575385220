import SLink from "../soul/link/SLink";
import {AnchorType} from "../soul/link/LinkTypes";
import * as React from "react";


export type menuType = {url: string, label: string, icon?: string, type?: string, active?: string, wideOnMobile?: boolean, enabled: boolean};

const generateMenu = (menu: menuType[]) =>
    menu.map((item: menuType) => (
        <li className="l-block-list__item" key={item.url}>
            <SLink to={item.url} linkType={AnchorType.PLAIN} {...item.icon}>{item.label}</SLink>
        </li>
    ));

interface MenuProps {
    menu: menuType[]
}

const FooterMenu: React.FC<MenuProps> = (props) =>
    <ul className="l-block-list l-block-list--transparent l-block-list--stretched">
        {generateMenu(props.menu)}
    </ul>;

export default FooterMenu;
