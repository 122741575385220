import * as React from 'react';
import {PropsWithChildren} from 'react';
import {ColSize} from "./EstecoColumnsSizes";

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface EstecoColumnProps {
    size?: ColSize,
    tag?: 'div' | 'section',
    id?: string,
    fullWidthBG?: boolean,
    className?: string,
}

const EstecoColumn: React.FC<PropsWithChildren<EstecoColumnProps>> = ({size, tag, id,  fullWidthBG, className, children}) => {
    const tagName: keyof JSX.IntrinsicElements = tag || 'div' as keyof JSX.IntrinsicElements;
    let classes: string = `esteco-column${size || ColSize.DEFAULT}`;

    if (fullWidthBG) {
        return React.createElement(tagName, {className: className, id: id}, <div className={classes}>{children}</div>);
    }

    if (!!className) {
        classes += ` ${className}`;
    }

    return React.createElement(tagName, {className: classes, id: id}, children);
}

export default EstecoColumn;
