import * as React from 'react';
import SEO from '../components/SEO';
import Footer from '../components/footer/Footer';
import EstecoColumn from "../components/estecoColumn/EstecoColumn";
import GeneralNavbar from "../components/header/hero/GeneralNavbar";
import '../styles/404.scss';
import SLink from "../components/soul/link/SLink";
import {ButtonType} from "../components/soul/link/LinkTypes";

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
const NotFoundPage = () => (
    <React.Fragment>
        <GeneralNavbar />
        <SEO/>
        <EstecoColumn className="soul-space-stack-both-3-xl">
            <div className="soul-space-stack-both-3-xl">
                <div className="soul-empty-state soul-empty-state--not-found  soul-empty-state--m">
                    <div className="soul-empty-state__image"></div>
                    <strong className="soul-empty-state__title">Page not found</strong>
                    <p className="soul-empty-state__description">We can’t find the page you’re looking for. You can find lots to explore on our home page</p>
                    <div className="soul-empty-state__actions">
                        <SLink to="/" linkType={ButtonType.PRIMARY}>Go to Home Page</SLink>
                    </div>
                </div>
            </div>
        </EstecoColumn>
        <Footer/>
    </React.Fragment>
);

export default NotFoundPage;
