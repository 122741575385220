import * as React from 'react';
import {graphql, useStaticQuery} from "gatsby";
import Grid from "../../soul/grid/Grid";
import {GridDirection} from "../../soul/grid/GridDirection";
import {GHAlignment, GVAlignment} from "../../soul/grid/GridAlignment";
import GridItem from "../../soul/grid/GridItem";
import SLink from "../../soul/link/SLink";
import {AnchorType} from "../../soul/link/LinkTypes";
import {Icons} from "../../soul/icon/IconsSet";
import Logo from "../../../assets/img/ESTECO.inline.svg";
import Svg from "../../Svg";
import EstecoColumn from "../../estecoColumn/EstecoColumn";

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface GeneralNavbarProps {
    commons: {
        engineeringURL: string,
        engineeringLinkLabel: string
    }
}

const GeneralNavbar: React.FC = () => {
    const data:GeneralNavbarProps = useStaticQuery(componentQuery);

    return <nav className="engineering-nav">
            <EstecoColumn className="soul-space-stack-both-m">
                <Grid direction={GridDirection.HORIZONTAL} vAlignment={GVAlignment.CENTER} hAlignment={GHAlignment.SPACE_BETWEEN} wrap={true}>
                    <GridItem>
                        <Svg color="#59585B" className="esteco-logo"><Logo /></Svg>
                    </GridItem>
                    <GridItem>
                        <SLink to={data.commons.engineeringURL} linkType={AnchorType.GHOST} icon={Icons.FORWARD} iconAfter={true}>{data.commons.engineeringLinkLabel}</SLink>
                    </GridItem>
                </Grid>
            </EstecoColumn>
        </nav>;
}
const componentQuery = graphql`
    query commonContentQuery {
        commons: indexJson(jsonData: {eq: "commons"}) {
            engineeringURL
            engineeringLinkLabel
        }
    }
`;

export default GeneralNavbar;
