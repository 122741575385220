/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */

export enum ColSize {
    SUPER_WIDE = '--super-wide',
    WIDE = '--wide',
    DEFAULT = '',
    BLOG_HEADER = '--blog-header',
    NARROW = '--narrow',
    VIDEO = '--video',
    MEDIUM = '--medium',
    TINY = '--tiny'
}
